@import-normalize;

@font-face {
  font-family: 'Wizzy';
  src: url('../assets/fonts/wizzy-medium.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Wizzy';
  src: url('../assets/fonts/wizzy-medium.woff2');
  font-weight: 800;
  font-style: normal;
}

body {
  font-family: 'Wizzy', sans-serif;
  font-size: 1rem;
}

#✨ {
  position: relative;
  overflow: hidden;
  max-width: 900px;
  color: #604064;
  margin: 0 auto;
}

#🪟 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 0.75rem;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 22px 70px 4px rgba(30, 20, 31, 0.65), 0 0 0 1px rgba(0, 0, 0, 0);
  animation: 0.45s show-window cubic-bezier(0.33, 1, 0.68, 1);
  animation-fill-mode: both;

  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
}

.c-window__title {
  position: relative;
  z-index: 2;
  padding: 0 0.3rem;
  height: 3rem;
  font-size: 1.2em;

  border-radius: 0.75rem 0.75rem 0 0;
  background: rgba(188, 129, 198, 0.25);
  color: #ffffff;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.c-window__body {
  flex-grow: 1;
  background: #fff;
  border-radius: 0 0 0.75rem 0.75rem;
}

.c-titlebar__icon {
  height: 2.2rem;
  fill: currentColor;
  vertical-align: bottom;
}

.c-titlebar__mode {
  margin: 0 1.2rem 0 0;
  line-height: 1;
}

.c-mode-toggle {
  vertical-align: bottom;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.c-mode-toggle--active {
  display: none;
}

.c-mode-toggle__icon {
  width: 1em;
  margin-right: 0.5em;
  vertical-align: bottom;
  fill: currentColor;
}

#🎥 {
  margin-top: 3em;
  padding: 0;
  border-radius: 0.75rem;
  overflow: hidden;
  animation: 0.66s fade-in ease-out;
  animation-fill-mode: both;
  animation-delay: 0.4s;
}

.o-content-wrapper {
  padding: 1rem;
}

@keyframes show-window {
  0% {
    transform: scale(0.3) translateY(60%);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  66% {
    transform: translateY(0);
  }

  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.c-user {
  padding: 0;
  margin: 0;

  position: relative;

  img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  .c-user__name {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.3em 0.5em;

    font-family: system-ui, sans-serif;
    color: white;
    background: rgba(0, 0, 0, 0.75);
  }
}

.c-user-navigation {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}

.c-user-navigation__items {
  margin: -0.25rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.c-user-navigation__item {
  padding: 0.25rem;
  box-sizing: border-box;
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 0;

  @media screen and (min-width: 700px) {
    flex-basis: 25%;
  }
}

.c-user-navigation__button {
  flex-shrink: 0;
  flex-grow: 0;
  appearance: none;
  border-radius: 100%;
  color: white;
  background: #604064;
  width: 2em;
  height: 2em;
  text-align: center;
  padding: 0;
  margin: 0 0.75em;
  border: 0;

  &--previous {
    margin-left: 0;

    .c-user-navigation__arrow {
      margin-left: -0.2em;
    }
  }

  &--next {
    margin-right: 0;

    .c-user-navigation__arrow {
      margin-left: 0.2em;
    }
  }

  @media screen and (min-width: 700px) {
    width: 2.5em;
    height: 2.5em;
  }
}

.c-user-navigation__arrow {
  height: 60%;
  margin: 0 auto;
  fill: currentColor;
  vertical-align: middle;
}

.c-wizzy {
  display: flex;
  flex-flow: row wrap;
}

.c-presentation {
}

.c-presentation__focus {
  font-size: 1.6em;
}

.c-gallery {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.c-welcome {
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}

.c-welcome__users {
  display: flex;
  flex-basis: 100%;
  flex-flow: row nowrap;
  margin: 0 auto 0.5rem;
}

.c-welcome__spacer {
  width: 0.5rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.c-welcome__message--big {
  transform: translateY(0.2rem);
  font-size: 2.2em;
  line-height: 0.9;
  padding-bottom: 0.25rem;
}

.c-welcome__character {
  display: inline-block;
  animation-name: wiggle;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  &:nth-child(2) {
    animation-delay: 0.05s;
  }

  &:nth-child(3) {
    animation-delay: 0.1s;
  }

  &:nth-child(4) {
    animation-delay: 0.15s;
  }

  &:nth-child(5) {
    animation-delay: 0.2s;
  }

  &:nth-child(6) {
    animation-delay: 0.25s;
  }

  &:nth-child(7) {
    animation-delay: 0.3s;
  }

  &:nth-child(8) {
    animation-delay: 0.35s;
  }

  &:nth-child(9) {
    animation-delay: 0.4s;
  }

  &:nth-child(10) {
    animation-delay: 0.45s;
  }

  &:nth-child(11) {
    animation-delay: 0.5s;
  }

  &:nth-child(12) {
    animation-delay: 0.55s;
  }

  &:nth-child(13) {
    animation-delay: 0.6s;
  }

  &:nth-child(14) {
    animation-delay: 0.65s;
  }

  &:nth-child(15) {
    animation-delay: 0.7s;
  }

  &:nth-child(16) {
    animation-delay: 0.75s;
  }
}

.c-welcome-message__spacer {
  display: block;

  @media screen and (min-width: 500px) {
    display: inline-block;
    width: 0.25em;
  }
}

@keyframes wiggle {
  from {
    transform: translateY(0);
    color: currentColor;
  }
  to {
    transform: translateY(-0.1em);
    color: #bc81c6;
  }
}

.c-hearto {
  width: 2em;
  fill: currentColor;
  display: none;
  opacity: 0.25;

  @media screen and (min-width: 700px) {
    display: block;
  }
}
